<template>
  <section
    class="user-profile-entity-document-data"
    v-loading.fullscreen.lock="configureLoading"
    :element-loading-text="LoadingText"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
  <el-switch
  v-if="!getIsMobile"
  class="mt-1"
  v-model="value1"
  active-text="Table View"
  inactive-text="Tab View">
</el-switch>
    <el-row v-if="value1 && !getIsMobile">
      <el-col :md="6" :sm="6" :xs="24">
        <el-menu class="el-menu-vertical-demo sidemenu-one list-group sidemenu">
          <el-input
            class="search ml-1 mb-1 mt-1"
            placeholder="Search"
            v-model="search_string"
            clearable
            style="width:220px;margin-right:5px'
              "
          >
          </el-input>
          <el-menu-item
            v-for="(val, index) in entityDocumentTemplates"
            :key="index"
            border
          >
            <div :class="getClass(val)" @click="templateDocumentsSideMenu(val)">
              <img
                class="mr-1 mt-1"
                src="@/assets/img/icons/docs-file.svg"
                alt="icon"
                style="width: 20px; margin-left: 25px"
              />
              {{ val.name | truncate(25, "...") }}
            </div>
          </el-menu-item>
          <br />
        </el-menu>
      </el-col>
      <el-col class="secondcol" :md="18" :sm="18" :xs="24">
        <templateSubmittedDataTable
          v-if="showDataofTemplates"
          :isAEntityTemplateData="isAEntityTemplateData"
          :configurableDocumentId.sync="configId"
          :configurableDocumentData.sync="this.viewDataOfTemplates"
          :entityId="this.entityId"
          :entityDataId="this.entityDataId"
        />
      </el-col>
    </el-row>
  <el-row v-else-if="getIsMobile && !value1 || value1 == false"> 
    <el-col>
    <el-tabs @tab-click="handleClickTab()" type="border-card" class="mt-2">
      <el-tab-pane
        v-for="(val, index) in entityDocumentTemplates"
        :label="val.name"
        :key="index"
        border
        class="sidemenu"
      >
        <UserTemplatesData
          v-if="sendDataToTable"
          :configurableId="val.configurable_document_id"
          :entityIdForTable="entityIdForDataTable"
          :entityDataIdForTable="entityDataIdForDataTable"
        />
      </el-tab-pane>
    </el-tabs>
    </el-col>
</el-row>

  </section>
</template>
<script>
import { bus } from "../../main";
import { mapGetters } from "vuex";
import UserTemplatesData from "./userTemplatesDataInEntityProfile.vue";
const templateSubmittedDataTable = () =>
  import("@/components/templates/templateSubmittedData.vue");
export default {
  name: "templates-PreviewTemplate",
  props: ["entityId", "entityDataId"],
  data() {
    return {
      entityDocumentTemplates: [],
      array: [],
      documentTemplates: [],
      configureLoading: false,
      LoadingText: "",
      is_document_data: false,
      showDataofTemplates: false,
      configurableDocumentId: null,
      isAEntityTemplateData: false,
      configurableDocumentData: null,
      templates: [],
      configId: "",
      value1:true,
      sendDataToTable: false,
      entityIdForDataTable: this.entityId,
      entityDataIdForDataTable: this.entityDataId,
      viewDataOfTemplates: [],
      templatesCount: null,
      search_string: "",
      total: 0,
      currentPage: 1,
      myArray: [],
      pageSize: 5,
      page: 1,
      order_by: null,
    };
  },
  components: {
    UserTemplatesData,
    templateSubmittedDataTable
  },
  created() {
    bus.$emit("templates-length", {
      templatesCount: this.templates.length,
    });
  },
  async mounted() {
  //  this.fetchEntityDocumets()
   await this.fetchEntityDocumets();
  },
  computed: {
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("configurableDocuments", ["getConfigurableDocumentDataById"]),
    ...mapGetters("entities", [
      "getEntityDocumentsDataStatus",
      "getEntityDocumentsData",
      "getEntityDocumentsNotIncludingTemplatesData",
      "getTemplatesFieldsDataFromEntitiesData",
      "getTemplatesFieldsDataFromEntitiesStatus",
    ]),
    getClass() {
      return (data) => {
        if (
          this.$route.query.documentTemplateId == data.configurable_document_id
        ) {
          return "list-group-item list-group-item-action active";
        } else {
          return "list-group-item list-group-item-action";
        }
      };
    },
  },
  methods: {
    handleClickTab() {
      this.sendDataToTable = true;
    },
    async fetchEntityDocumets() {
      try {
        this.configureLoading = true;
        this.LoadingText = "Fetching Entity Document Templates...";
        this.entityDocumentTemplates = [];
        let params = {
          entity_id: this.entityId,
          entity_data_id: this.entityDataId,
        };
        if (this.search_string) {
          params.search_string = this.search_string;
        }
        await this.$store.dispatch("entities/fetchEntitiesDocuments", params);
        setTimeout(() => {
          this.configureLoading = false;
        }, 600);
        if (this.getEntityDocumentsDataStatus) {
          this.entityDocumentTemplates = this.getEntityDocumentsData;
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error in fetching entity documents",
          });
        }

        if (this.$route.query && this.$route.query.documentTemplateId) {
          await this.fetchTemplates();
          this.showDataofTemplates = true;
          this.configId = this.$route.query.documentTemplateId;
          this.configurableDocumentData = this.getConfigurableDocumentDataById;
          this.entityId = this.$route.params.entity_id;
          this.entityDataId = this.$route.query.dataId;
          this.isAEntityTemplateData = true;
        }
        this.fetchTemplates()
      } catch (err) {
        console.log("fetchEntityDocumets",err);
      }
    },

    async templateDocumentsSideMenu(data) {
      this.configureLoading = true;
      this.LoadingText = "Fetching Document Data...";
      this.showDataofTemplates = false;
      this.configureLoading = false;
      this.$router.push({
        path: `/entity_data_view/${this.$route.params.entity_id}/${
          this.$route.params.template_code
        }?dataId=${this.entityDataId || ""}&activeStep=${
          this.$route.query.activeStep
        }&documentTemplateId=${data.configurable_document_id}`,
      });
      this.configId = data.configurable_document_id
        ? data.configurable_document_id
        : this.$route.query.documentTemplateId;
    },
    async fetchTemplates() {
      try {
        this.configureLoading = true;
        this.LoadingText = "Fetching Template Documents Data...";
        let configurable_document_id = this.$route.query.documentTemplateId;
        await this.$store.dispatch(
          "configurableDocuments/fetchConfigurableDocumentById",
          {
            id: configurable_document_id,
          }
        );
        this.configureLoading = false;
        this.viewDataOfTemplates = this.getConfigurableDocumentDataById;
      } catch (err) {
        console.log("fetchTemplates",err);
      }
    },
    async fetchingSubmittedTemplatesData(data) {
      await this.fetchTemplates();
      this.configureLoading = true;
      this.LoadingText = "Fetching Template Documents Data...";
      this.showDataofTemplates = true;
      if (data && data.configurable_document_id) {
        this.isAEntityTemplateData = true;
        this.configurableDocumentId = data.configurable_document_id;
        this.configurableDocumentData = this.viewDataOfTemplates;
        bus.$emit("document-change", {
          configurableDocumentId: data.configurable_document_id
            ? data.configurable_document_id
            : this.$route.query.documentTemplateId,
          configurableDocumentData: this.getConfigurableDocumentDataById,
          isAEntityTemplateData: this.isAEntityTemplateData,
        });
      }
      this.configureLoading = false;
    },
  },
  watch: {
    search_string: function (val) {
      this.search_string = val;
      this.fetchEntityDocumets();
    },
    "$route.query.documentTemplateId"(step) {
      let data =
        this.entityDocumentTemplates &&
        this.entityDocumentTemplates.length &&
        this.entityDocumentTemplates.filter((el) => {
          if (el && el.configurable_document_id === step) {
            this.isAEntityTemplateData = true;
            this.myArray.push(el);
            return el;
          }
        });
      if (data && data.length) {
        this.isAEntityTemplateData = true;
        this.fetchingSubmittedTemplatesData(data);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.list-group {
  margin-top: 30px;
  width: 255px;
  border-left: 2px #9299b8;
  border-top: 2px #9299b8;
  border-bottom: 1px ridge;
  .list-group-item {
    margin-right: -20px;

    margin-left: -35px;
    padding: 0em;
    border: none;
    color: #9299b8;
    &.active {
      color: #f754a2;
      border-left: 3px solid #f754a2;
    }
  }
}
.secondcol {
  margin-top: 20px;
  width: 75%;
  @media (max-width: 766.98px) {
    width: 70%;
  }
}
.sidemenu {
  height: 410px;
  overflow-y: scroll;
  overflow-x: scroll;
  border-bottom: 2px solid #f5f5f5;
  border-right: 1px ridge;
  border-left: 2px solid #f5f5f5;
  border-top: 2px solid #f5f5f5;
}
.sidemenu::-webkit-scrollbar {
  width: 0.6em;
}
.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}
</style>
<style>
.el-table .cell {
  word-break: break-word;
}
</style>